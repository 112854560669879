<template>
  <ion-page>
    <ion-header>
      <ion-grid fixed style="padding: 0">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button default-href="/home" :disabled="isMassaging || echo.running"></ion-back-button>
          </ion-buttons>

          <ion-title class="ion-text-center" style="font-size: 26px" color="secondary">
            {{ t('tools') }}
          </ion-title>
          
          <ion-buttons slot="end">
            <ion-button router-link="/sounds">
              <ion-icon slot="icon-only" :icon="musicalNotes"></ion-icon>
            </ion-button>
          </ion-buttons>
        </ion-toolbar>
      </ion-grid>
    </ion-header>

    <ion-content>

      <ion-grid fixed>

        <ion-list class="ion-padding-horizontal">

          <!-- 哄寵物開心 (beep beep sounds) -->
          <ion-item color="none" :class="{ 'tool-btn-inactive': !happySound.isPlaying, 'tool-btn-active': happySound.isPlaying }" class="tool-btn" lines="none"
                    @click="playAudio(happySound)" @touchstart="checkLongPress(happySound)" @touchend="stopAudio(happySound)" button>
            <ion-thumbnail slot="start" v-show="!happySound.isPlaying">
              <img :src="require('@/assets/tools/happy.png')">
            </ion-thumbnail>
            <ion-thumbnail slot="start" v-show="happySound.isPlaying">
              <img :src="require('@/assets/tools/white_happy.png')">
            </ion-thumbnail>
            <!--<ion-icon slot="start" :icon="radioButtonOff" v-show="!happySound.isPlaying"></ion-icon>
            <ion-icon slot="start" :icon="radioButtonOn" v-show="happySound.isPlaying"></ion-icon>-->
            <ion-label>
              <h1>{{ happySound.title }}</h1>
            </ion-label>
            <ion-chip @click.stop="setPopoverOpen(true, $event)" @touchstart.stop="null">
              <ion-icon :icon="musicalNote"></ion-icon>
              <ion-label style="max-width: 50px"><p>{{ happySound.fileName }}</p></ion-label>
            </ion-chip>
          </ion-item>

          <!-- 哄寵物入睡 (sleep) -->
          <ion-item color="none" :class="{ 'tool-btn-inactive': sleepSound.howlerObj == null, 'tool-btn-active': sleepSound.howlerObj != null }"
                    class="tool-btn" lines="none" @click="toggleMusic(sleepSound)" button>
            <ion-thumbnail slot="start" v-show="!sleepSound.howlerObj && !sleepSound.isPlaying">
              <img :src="require('@/assets/tools/sleep.png')">
            </ion-thumbnail>
            <ion-icon slot="start" :icon="play" v-show="sleepSound.howlerObj && !sleepSound.isPlaying"></ion-icon>
            <ion-icon slot="start" :icon="pause" v-show="sleepSound.isPlaying"></ion-icon>
            <ion-label>
              <h1>{{ sleepSound.title }}</h1>
            </ion-label>
            <ion-buttons slot="end" @click.stop="destroyMusic(sleepSound)" v-show="sleepSound.howlerObj != null">
              <ion-button slot="icon-only">
                <ion-icon :icon="close"></ion-icon>
              </ion-button>
            </ion-buttons>
          </ion-item>
          
          <!-- Pet Camera -->
          <ion-item color="none" class="tool-btn tool-btn-inactive" lines="none" @click="takePetPhotos()" button>
            <ion-thumbnail slot="start">
              <img :src="require('@/assets/tools/camera.png')">
            </ion-thumbnail>
            <!--<ion-icon slot="start" :icon="camera"></ion-icon>-->
            <ion-label>
              <h1>幫寵物影相</h1>
            </ion-label>
            <ion-chip @click.stop="setPopoverOpen(true, $event, 'camera')" @touchstart.stop="null">
              <ion-icon :icon="musicalNote"></ion-icon>
              <ion-label style="max-width: 50px"><p>{{ currCameraSound.name }}</p></ion-label>
            </ion-chip>
          </ion-item>

          <!-- 震機按摩 -->
          <ion-item color="none" :class="{ 'tool-btn-inactive': !isMassaging, 'tool-btn-active': isMassaging }" class="tool-btn"
                    lines="none" @click="toggleMassage()" button>
            <ion-thumbnail slot="start" v-show="!isMassaging">
              <img :src="require('@/assets/tools/massage.png')">
            </ion-thumbnail>
            <!--<ion-icon slot="start" :icon="power" v-show="!isMassaging"></ion-icon>-->
            <ion-icon slot="start" :icon="stopCircleOutline" v-show="isMassaging"></ion-icon>
            <ion-label>
              <h1>幫寵物按摩</h1>
            </ion-label>
          </ion-item>

          <!-- Label only -->
          <ion-segment mode="ios" v-model="currMassageMode" @ionChange="vibratePhone($event.target.value)" v-show="isMassaging">
            <ion-segment-button value="normal">
              <ion-label>正常</ion-label>
            </ion-segment-button>
            <ion-segment-button value="slow">
              <ion-label>輕鬆</ion-label>
            </ion-segment-button>
            <ion-segment-button value="fast">
              <ion-label>刺激</ion-label>
            </ion-segment-button>
          </ion-segment>
<!--
          <ion-item :color="isFlashingLight ? 'tertiary' : null" class="ion-margin-vertical round-border-item" lines="none" @click="toggleFlashlightLoop()" button>
            <ion-icon slot="start" :icon="flashlightOutline" v-show="!isFlashingLight"></ion-icon>
            <ion-icon slot="start" :icon="stopCircleOutline" v-show="isFlashingLight"></ion-icon>
            <ion-label>
              <h1>閃光燈引狗狗注意</h1>
            </ion-label>
          </ion-item>
-->
          <!-- Echo Pet Sounds -->

          <ion-item color="none" :class="{ 'tool-btn-inactive': !echo.running, 'tool-btn-active': echo.running }" class="tool-btn"
                    lines="none" @click="toggleEchoSounds()" button>
            <ion-thumbnail slot="start" v-show="!echo.running">
              <img :src="require('@/assets/tools/echo.png')">
            </ion-thumbnail>
            <!--<ion-icon slot="start" :icon="mic" v-show="!echo.running"></ion-icon>-->
            <ion-icon slot="start" :icon="stopCircleOutline" v-show="echo.running"></ion-icon>
            <ion-label>
              <h1>寵物回聲機</h1>
            </ion-label>
          </ion-item>
<!--
          <ion-item class="ion-margin-vertical round-border-item" lines="none" @click="openDogMirror()" button>
            <ion-icon slot="start" :icon="phonePortraitOutline" v-show="!echo.running"></ion-icon>
            <ion-label>
              <h1>狗狗照鏡</h1>
            </ion-label>
          </ion-item>
          -->

          <!-- Pet Toy -->
          <ion-item style="margin-bottom: 0" color="none" class="tool-btn tool-btn-inactive" lines="none" @click="startPlayToy()" button>
            <ion-thumbnail slot="start">
              <img :src="require('@/assets/tools/toy.png')">
            </ion-thumbnail>
            <!--<ion-icon slot="start" :icon="gameController"></ion-icon>-->
            <ion-label>
              <h1>玩玩具</h1>
            </ion-label>
            <ion-chip @click.stop="setPopoverOpen(true, $event, 'toy')" @touchstart.stop="null">
              <ion-icon :icon="musicalNote"></ion-icon>
              <ion-label style="max-width: 50px"><p>{{ currToySound.name }}</p></ion-label>
            </ion-chip>
          </ion-item>

        </ion-list>

        <ion-popover :is-open="isPopoverOpenRef" :event="popoverEvent" @didDismiss="setPopoverOpen(false)" :dismiss-on-select="true">
          <ion-content>
            <ion-list>
              <ion-item lines="full" v-for="sound in defaultSounds" :key="sound.name" @click="setEffectSoundFilePath(sound)">
                <ion-icon slot="start" :icon="musicalNote"></ion-icon>
                <ion-label>{{ sound.name }}</ion-label>
              </ion-item>
              <ion-item lines="full" v-for="sound in recordedSounds" :key="sound.id" @click="setEffectSoundFilePath(sound)" v-show="currTargetTool != 'toy'">
                <ion-icon slot="start" :icon="musicalNote"></ion-icon>
                <ion-label>{{ sound.name }}</ion-label>
              </ion-item>
            </ion-list>
          </ion-content>
        </ion-popover>

        <div class="ion-text-center mloltsai-container">
          <img :src="require('@/assets/tools/mloltsai/happy.png')" v-show="happySound.isPlaying" />
          <img :src="require('@/assets/tools/mloltsai/sleep.png')" v-show="sleepSound.isPlaying" />
          <img :src="require('@/assets/tools/mloltsai/massage.png')" v-show="isMassaging" />
          <img :src="require('@/assets/tools/mloltsai/echo.png')" v-show="echo.isPlayingBack" />
        </div>
        
        <ion-text class="ion-text-center" v-show="echo.running">
          <h2 v-show="!echo.isRecording && !echo.isPlayingBack">
            <ion-icon class="icon-lg" :icon="earOutline"></ion-icon>
            <div class="ion-padding-top"></div>
            等待叫聲中...
          </h2>
          <h2 v-show="echo.isRecording">
            <ion-icon class="icon-lg" :icon="mic"></ion-icon>
            <div class="ion-padding-top"></div>
            錄音中...
          </h2>
          <h2 v-show="echo.isPlayingBack">
            <!--<ion-icon class="icon-lg" :icon="megaphoneOutline"></ion-icon>-->
            <div class="ion-padding-top"></div>
            回聲播放中...
          </h2>  
        </ion-text>

      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
// Vue reactivity
import { computed, reactive, ref } from 'vue';

// icons
import { add, close, search, navigate, arrowBack, arrowForward, bookmarksOutline, timeOutline,
         swapVerticalOutline, trashOutline, heart, heartOutline, shareSocialOutline,
         play, pause, camera, power, stopCircleOutline, flashlightOutline,
         radioButtonOff, radioButtonOn, mic, phonePortraitOutline, gameController,
         musicalNote, musicalNotes, earOutline, megaphoneOutline } from 'ionicons/icons';

// components
import { IonPage, IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonIcon,
        IonThumbnail, IonButtons, IonButton, IonModal, IonImg,
        IonSegment, IonSegmentButton, IonChip, IonBackButton, IonCheckbox, IonBadge,
        IonInfiniteScroll, IonInfiniteScrollContent, IonAvatar, IonSelect, IonSelectOption,
        IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonPopover, IonText,
        loadingController, onIonViewWillLeave, onIonViewDidEnter, isPlatform, } from '@ionic/vue';
import LoadingSkeleton from "@/components/LoadingSkeleton.vue";
import DogWalkingModal from '@/components/modals/DogWalkingModal.vue';

// composables
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import { utils } from '@/composables/utils';

// plugins
import { Howl, Howler } from 'howler';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Preferences } from '@capacitor/preferences';
import { Flashlight } from '@awesome-cordova-plugins/flashlight';
import { Vibration } from '@awesome-cordova-plugins/vibration';
import { Media } from '@awesome-cordova-plugins/media';
import { CameraPreview, CameraPreviewPictureOptions, CameraPreviewOptions, CameraPreviewDimensions } from '@awesome-cordova-plugins/camera-preview';
import { MediaCapture, MediaFile, CaptureError, CaptureImageOptions } from '@awesome-cordova-plugins/media-capture';

export default {
  name: 'ToolListPage',
  components: { IonHeader, IonSearchbar, IonToolbar, IonTitle, IonContent, IonPage,
                IonGrid, IonRow, IonCol, IonList, IonItem, IonLabel, IonIcon,
                IonThumbnail, IonButtons, IonButton, IonCheckbox, IonBadge, IonModal, IonImg,
                IonSegment, IonSegmentButton, IonChip, IonBackButton,
                IonInfiniteScroll, IonInfiniteScrollContent, IonAvatar, IonSelect, IonSelectOption,
                IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonPopover, IonText,
                LoadingSkeleton, },
  setup() {
    const { t } = useI18n();
    const store = useStore();
    const router = useRouter();
    const { sleep, openModal, presentPrompt, getMediaFileName, } = utils();

    // 1. declare state variables (ref to make them reactive)
    const currUser = computed(() => store.state.user);

    // massage
    const currMassageMode = ref("normal");
    const isMassaging = ref(false); // is device vibrating for massge
    let vibrationInterval = null;

    // flashing light
    const isFlashingLight = ref(false); // is flashing light
    let flashLightInterval = null;

    // echo
    const echo = reactive({
      running: false,
      isRecording: false,
      isPlayingBack: false,
    });
    let mediaObj = null, startRecordingTime = null;

    // sound / music
    let pressTimer;
    let isLooping = false;
    let checkMusicInterval = null, currPlayingSound;

    const defaultSounds = [
      { name: '鈴鈴', url: require('@/assets/sounds/beep.mp3'), oneOffTrack: [10, 100], loopTrack: [40, 80, true] },
      //{ name: '玩具', url: require('@/assets/sounds/toy.mp3'), oneOffTrack: [100, 1000], loopTrack: [200, 300, true] },
      { name: '玩具', url: require('@/assets/sounds/toy.mp3'), oneOffTrack: [100, 1000], loopTrack: [50, 7500, true] },
      { name: '寶寶', url: require('@/assets/sounds/bb.mp3'), oneOffTrack: [100, 1000], loopTrack: [50, 12000, true] },
    ];
    const recordedSounds = ref([]);
    const happySound = reactive({ title: "哄寵物開心", url: defaultSounds[0].url, isPlaying: false, type: 'effect', fileName: defaultSounds[0].name, mediaObjs: [], });
    const sleepSound = reactive({ title: "哄寵物入睡", url: require('@/assets/sounds/sleep.mp3'), isPlaying: false, type: 'music', });
    const SOUND_STORAGE_KEY = 'sounds';
    const LAST_USED_SOUND_KEY = 'lastUsedSound';
    const MAX_MEDIA_OBJS = 10;
    let currMediaObjIdx = 0;

    // toy
    const currToySound = ref(defaultSounds[0]);
    const LAST_TOY_SOUND_KEY = 'lastToySound';

    // camera
    const currCameraSound = ref(defaultSounds[0]);
    const LAST_CAMERA_SOUND_KEY = 'lastCameraSound';
    const latestPhotoPath = ref("");

    // sound select popover
    const currTargetTool = ref(''); // toy / happy
    const isPopoverOpenRef = ref(false);
    const popoverEvent = ref();
    const setPopoverOpen = (state, ev, targetTool = '') => {
      popoverEvent.value = ev; 
      isPopoverOpenRef.value = state;
      currTargetTool.value = targetTool;
    }

    /**
     * Stop running tools before triggering another
     */
    const destroyMusic = (sound) => {
      Howler.stop();
      if (sound) {
        sound.howlerObj = null;
        sound.isPlaying = false;
      }
      currPlayingSound = null;
    }
    const releaseGlobalMediaObj = () => {
      if (mediaObj) {
        mediaObj.stopRecord();
        mediaObj.release();
        mediaObj = null;
      }
    }
    const stopAllExistingTools = () => {
      echo.running = false;
      releaseGlobalMediaObj();

      isMassaging.value = false;
      Vibration.vibrate(0); // stop vibration
      if (vibrationInterval) clearInterval(vibrationInterval);

      if (currPlayingSound) destroyMusic(currPlayingSound);
    }

    /**
     * Sound effects (e.g. beep)
     */
    const playAudio = async (sound, loop = false) => {
      stopAllExistingTools();

      sound.isPlaying = true;

      if (sound.filePath) { // self-defined sounds
        if (!loop) {
          if (sound.mediaObjs.length <= MAX_MEDIA_OBJS) {
            const mediaObj = Media.create(sound.filePath);
            sound.mediaObjs.push(mediaObj);
            mediaObj.play();
          } else {
            const targetMediaObj = sound.mediaObjs[currMediaObjIdx++];
            targetMediaObj.stop();
            targetMediaObj.play();

            if (currMediaObjIdx > sound.mediaObjs.length-1) {
              currMediaObjIdx = 0; // go back to beginning of the circular queue
            }
          }
          setTimeout(() => {
            if (!isLooping) {
              sound.isPlaying = false;
            }
          }, sound.duration * 1000);
        } else {
          isLooping = true;
        }
      } else { // default sound
        const howlSound = new Howl({
          src: sound.url,
          sprite: {
            track01: sound.oneOffTrack || [10, 100],
            track02: sound.loopTrack || [40, 80, true],
          }
        });
        if (!loop) {
          Vibration.vibrate(500);
          howlSound.play('track01');
          setTimeout(() => {
            if (!isLooping) {
              sound.isPlaying = false;
            }
          }, 200);
        } else {
          isLooping = true;
          howlSound.play('track02');
        }
      }
    }
    const checkLongPress = (sound) => {
      playAudio(sound);

      pressTimer = setTimeout(() => {
        playAudio(sound, true);
      }, 200)
    }
    const stopAudio = (sound) => {
      if (pressTimer) {
        clearTimeout(pressTimer);
      }
      if (isLooping) {
        setTimeout(() => {
          Howler.stop();
          isLooping = false;
        }, 10);
      }
      if (sound) {
        sound.isPlaying = false;
      }
    }
    const setEffectSoundFilePath = (sound) => {
      if (currTargetTool.value == 'toy') {
        currToySound.value = { ...sound };
        Preferences.set({
          key: LAST_TOY_SOUND_KEY,
          value: JSON.stringify(currToySound.value)
        });
      } else if (currTargetTool.value == 'camera') {
        currCameraSound.value = { ...sound };
        Preferences.set({
          key: LAST_CAMERA_SOUND_KEY,
          value: JSON.stringify(currCameraSound.value)
        });
      } else {
        const { name, filePath, duration, url, oneOffTrack, loopTrack, } = sound;
        happySound.url = url || "";
        happySound.fileName = name;
        happySound.filePath = filePath;
        happySound.duration = duration || "";
        happySound.oneOffTrack = oneOffTrack || "";
        happySound.loopTrack = loopTrack || "";

        // release media objects
        for (const mediaObj of happySound.mediaObjs) {
          mediaObj.stop();
          mediaObj.release();
        }
        happySound.mediaObjs = [];

        // save last used sound
        Preferences.set({
          key: LAST_USED_SOUND_KEY,
          value: JSON.stringify(happySound)
        });
      }
    }

    /**
     * Music (e.g. relaxing dogs)
     */
    const toggleMusic = (sound) => {
      if (sound.isPlaying) {
        sound.howlerObj.pause();
      }
      else {
        stopAllExistingTools();

        const howlSound = sound.howlerObj || new Howl({ src: sound.url, loop: true });
        howlSound.play();
        sound.howlerObj = howlSound;
        currPlayingSound = sound;
      }
      sound.isPlaying = !sound.isPlaying;
    }

    /**
     * Photo taking
     */
    const takePetPhotos = () => {
      presentPrompt(t('friendlyReminder'), t('confirmTakePetPhotos'), async () => {
        stopAllExistingTools();
        //const targetSound = { ...happySound, ...defaultSounds[1], loopTrack: [50, 7500, true] };
        const targetSound = currCameraSound.value;
        playAudio(targetSound, true);
        try {
          const cameraPhoto = await Camera.getPhoto({
            resultType: CameraResultType.Uri,
            source: CameraSource.Camera,
            quality: 80,
            width: 2000,
            saveToGallery: true,
            presentationStyle: 'popover',
          });
          latestPhotoPath.value = cameraPhoto.webPath;
        } catch (e) {
          console.log(e);
        } finally {
          stopAudio(targetSound); // stop sound
        }
      })
    }

    /**
     * TODO: Video taking
     */

    const takePetVideo = async () => {
      const video = await MediaCapture.captureVideo();
      console.log(video);
    }

    /**
     * Massage (vibration)
     */
    const loopVibrationPattern = (pattern, interval) => {
      if (vibrationInterval) {
        Vibration.vibrate(0); // stop vibration
        clearInterval(vibrationInterval); // stop existing vibration first
      }
      Vibration.vibrate(pattern);
      vibrationInterval = setInterval(() => {
        Vibration.vibrate(pattern);
      }, interval);
    }
    const vibratePhone = (mode) => {
      switch (mode) {
        case 'slow':
          loopVibrationPattern(1000, 3000);
          break;
        case 'fast':
          //loopVibrationPattern([300, 50, 100, 50, 100], 1000);
          if (isPlatform('android')) {
            loopVibrationPattern([100,30,100,30,100,30,200,30,200,30,200,30,100,30,100,30,100], 1000); // Vibrate 'SOS' in Morse.
          } else {
            loopVibrationPattern(1000, 50); // in ios the vibration pattern is static
          }
          break;
        default: // normal pattern
          if (isPlatform('android')) {
            loopVibrationPattern(2000, 3000);
          } else {
            loopVibrationPattern(1000, 1500);
          }
          break;
      }
    }
    const toggleMassage = () => {
      if (isMassaging.value == true) {
        Vibration.vibrate(0); // stop vibration
        clearInterval(vibrationInterval);
      } else {
        stopAllExistingTools();
        vibratePhone('normal');
      }
      isMassaging.value = !isMassaging.value;
    }

    /**
     * Torch / Flashlight (flashing)
     */
    const toggleFlashlightLoop = () => {
      if (isFlashingLight.value == true) {
        Flashlight.switchOff();
        isFlashingLight.value = false;
        clearInterval(flashLightInterval);
      } else {
        isFlashingLight.value = true;
        flashLightInterval = setInterval(() => {
          Flashlight.toggle();
        }, 100);
      }
    }

    /**
     * Echoing Sounds
     */
    const checkEchoSound = async () => {
      if (echo.running == true) {
        if (mediaObj == null) {
          mediaObj = Media.create( getMediaFileName('echo') );
          mediaObj.startRecord();
          startRecordingTime = new Date();
        }
        // get media amplitude
        const amp = await mediaObj.getCurrentAmplitude();
        if (amp > 0.5) {
          echo.isRecording = true;
          const startPos = new Date() - startRecordingTime;
          setTimeout(() => {
            mediaObj.stopRecord();
            mediaObj.play();
            mediaObj.seekTo(Math.max(0, startPos-500));
            echo.isRecording = false;
            echo.isPlayingBack = true;
            setTimeout(() => {
              mediaObj.stop();
              mediaObj.release();
              mediaObj = null;
              echo.isPlayingBack = false;
              checkEchoSound();
            }, 3000);
          }, 3000);

          // sound detected, stop the recording after 3 secs then do playback
          /*
          const tmpMediaObj = Media.create( getMediaFileName(new Date().valueOf()) );
          tmpMediaObj.startRecord();
          setTimeout(() => {
            tmpMediaObj.stopRecord();
            tmpMediaObj.play();
            setTimeout(() => {
              tmpMediaObj.stop();
              tmpMediaObj.release();
              checkEchoSound();
            }, 3000);
          }, 3000)
          */
        } else {
          setTimeout(checkEchoSound, 300); // check every 0.3 seconds
        }
      }
    }
    const toggleEchoSounds = () => {
      if (echo.running == true) {
        echo.running = false;
        releaseGlobalMediaObj();
      } else {
        stopAllExistingTools();
        echo.running = true;
        checkEchoSound();
      }
    }

    /**
     * Mirror
     */
    const openDogMirror = () => {
      //await openModal(DogWalkingModal, {});
      const cameraPreviewOptions = {
        camera: CameraPreview.CAMERA_DIRECTION.FRONT,
        tapPhoto: false,
        toBack: true,
        alpha: 1
      };
      CameraPreview.startCamera(cameraPreviewOptions);
      CameraPreview.onBackButton(() => {
        console.log('Back button pushed');
        CameraPreview.stopCamera();
      });
    }

    /**
     * Toy
     */
    const startPlayToy = async () => {
      presentPrompt("", t('confirmPlayToy'), () => {
        stopAllExistingTools();
        router.push({ name: 'ToyPage', state: { sound: JSON.stringify(currToySound.value) } });
      });
    }

    /**
     * On enter / leave view
     */
    onIonViewDidEnter(() => {
      checkMusicInterval = setInterval(() => {
        if (currPlayingSound && currPlayingSound.howlerObj) {
          const { howlerObj } = currPlayingSound;
          const currSeek = howlerObj.seek();
          console.log(currSeek);
          if (currSeek < 10) howlerObj.rate(2.5);
          else if (currSeek < 20) howlerObj.rate(2.0);
          else if (currSeek < 30) howlerObj.rate(1.5);
          else if (currSeek < 40) howlerObj.rate(1.0);
          else if (currSeek < 50) howlerObj.rate(0.5);
        }
      }, 1000);

      // Retrieve recorded sounds
      Preferences.get({ key: SOUND_STORAGE_KEY }).then(ret => {
        recordedSounds.value = JSON.parse(ret.value || '[]');
      });

      // Retrieve last used effect sound
      Preferences.get({ key: LAST_USED_SOUND_KEY }).then(ret => {
        if (ret.value) {
          const soundObj = JSON.parse(ret.value);
          const { fileName, filePath, duration, url, oneOffTrack, loopTrack } = soundObj;
          
          happySound.fileName = fileName;
          happySound.filePath = filePath;
          happySound.url = url || "";
          happySound.duration = duration || "";
          happySound.oneOffTrack = oneOffTrack || "";
          happySound.loopTrack = loopTrack || "";
        }
      });
      
      // Retrieve last used toy sound
      Preferences.get({ key: LAST_TOY_SOUND_KEY }).then(ret => {
        if (ret.value) {
          currToySound.value = JSON.parse(ret.value);
        }
      });

      // Retrieve last used camera sound
      Preferences.get({ key: LAST_CAMERA_SOUND_KEY }).then(ret => {
        if (ret.value) {
          currCameraSound.value = JSON.parse(ret.value);
        }
      });
    })
    onIonViewWillLeave(() => { // clean-up
      clearInterval(checkMusicInterval);
      if (currPlayingSound) destroyMusic(currPlayingSound);
      if (vibrationInterval) clearInterval(vibrationInterval);
      if (flashLightInterval) clearInterval(flashLightInterval);
      releaseGlobalMediaObj();
    })

    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      add, close, search, navigate, arrowBack, arrowForward, bookmarksOutline, timeOutline,
      swapVerticalOutline, trashOutline, heart, heartOutline, shareSocialOutline,
      play, pause, camera, power, stopCircleOutline, flashlightOutline,
      radioButtonOff, radioButtonOn, mic, phonePortraitOutline, gameController,
      musicalNote, musicalNotes, earOutline, megaphoneOutline,

      // variables
      currUser, recordedSounds,
      happySound, sleepSound,
      latestPhotoPath,
      isMassaging, currMassageMode,
      isFlashingLight,
      echo,
      isPopoverOpenRef, popoverEvent,
      defaultSounds,
      currToySound, currCameraSound,
      currTargetTool,

      // methods
      t,
      playAudio, checkLongPress, stopAudio,
      toggleMusic, destroyMusic,
      takePetPhotos,
      toggleFlashlightLoop,
      toggleMassage, vibratePhone,
      toggleEchoSounds,
      openDogMirror,
      startPlayToy,
      setPopoverOpen, setEffectSoundFilePath,
    }
  }
}
</script>

<style scoped>
  h1 {
    letter-spacing: 2px;
    font-weight: 500;
    font-size: 24px;
    color: #424242;
    white-space: normal;
  }
  .tool-btn {
    margin-top: 16px;
    margin-bottom: 16px;
    border-radius: 25px;
    background-size: contain;
  }
  .tool-btn-inactive {
    background-image: url('../../assets/btn_inactive.png');
  }
  .tool-btn-active {
    background-image: url('../../assets/btn_active.png');
    color: #fff;
  }
  .tool-btn-active h1 {
    color: #fff;
  }
  .tool-btn ion-thumbnail {
    width: 32px;
    height: 32px;
  }
  ion-item ion-icon[slot="start"] {
    margin-inline-end: 16px;
  }
  .mloltsai-container img {
    width: 50%;
  }
</style>